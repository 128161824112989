@font-face {
	font-family: 'Poppins-Regular.ttf';
	src: url(../../assets/fonts/Poppins-Regular.ttf);
}
@font-face {
	font-family: 'Poppins-Bold.ttf';
	src: url(../../assets/fonts/Poppins-Bold.ttf);
}

#login {
	background-image: url(../../assets/images/login/bg1.png);
	height: 100vh;
	overflow: hidden;
	background-repeat: no-repeat;
	background-position: top center;
	background-size: cover;
	font-size: 18px;
	color: var(--bg-color-inverted);
	padding: 0px 0 60px 0;
	margin-top: -5px;

	& .header-img {
		width: 100%;
	}

	& .img-izq {
		width: 75%;
	}

	& .contenedor {
		background-color: rgba($color: #00f5d8, $alpha: 0.5);
		height: 100vh;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 15px;

		// padding: 0px;
		// border-radius: 20px;

		.MuiInputBase-input {
			font-family: 'Poppins-Regular.ttf';
			background-color: #fff;
			color: #707070 !important;
			font-size: 18px;
		}

		& .MuiInput-underline {
			&::after {
				border-bottom: 2px solid #707070 !important;
			}

			&::before {
				border-bottom: 2px solid #707070 !important;
			}
		}

		& .MuiFormLabel-root {
			&.Mui-focused {
				font-family: 'Poppins-Regular.ttf';
				color: #707070 !important;
			}
		}

		& .MuiInputLabel-outlined.MuiInputLabel-shrink {
			transform: translate(0px, -18px) scale(1) !important;
			font-family: 'Poppins-Regular.ttf';
			color: #707070 !important;
		}

		& .MuiFormLabel-root {
			color: #707070 !important;
		}

		& .MuiFilledInput-underline {
			&::before,
			&::after {
				border: none !important;
			}
		}

		& .MuiOutlinedInput-notchedOutline {
			border: #707070 !important;
		}

		& .MuiFormLabel-root.Mui-focused {
			font-weight: normal;
			font-size: 16px;
		}

		& button {
			background-color: #e3e829;
			font-family: 'Poppins-Bold.ttf';
			color: #707070;
			padding: 15px;
			width: 120px;
			font-size: 18px;
			margin: auto;
		}
	}
}

@media only screen and (max-width: 600px) {
	#login {
		overflow: scroll;
		height: auto;

		& .header-img {
			width: 170%;
		}
		& .img-izq {
			width: 50%;
			margin-top: 5%;
		}

		& .contenedor {
			height: 90vh;
			display: block;
			& img {
				width: 50%;
			}
		}
	}
}
@media only screen and (min-width: 600px) {
	#login {
		overflow: scroll;
		height: 100vh;

		& .header-img {
			width: 100%;
		}
		& .img-izq {
			width: 50%;
			margin-top: 5%;
		}

		& .contenedor {
			height: 100vh;
			display: block;
			& img {
				width: 90%;
			}
		}
	}
}
@media only screen and (min-width: 768px) {
	#login {
		overflow: hidden;
		height: auto;

		& .header-img {
			width: 100%;
		}
		& .img-izq {
			width: 75%;
			margin-top: 5%;
		}

		& .contenedor {
			height: 40vh;
			display: block;
			& img {
				width: 90%;
			}
		}
	}
}
@media only screen and (min-width: 992px) {
	#login {
		overflow: hidden;
		height: 100vh;

		& .header-img {
			width: 100%;
		}
		& .img-izq {
			width: 75%;
			margin-top: 5%;
		}

		& .contenedor {
			height: 100vh;
			display: flex;
			& img {
				width: 90%;
			}
		}
	}
}
@media only screen and (min-width: 1200px) {
	#login {
	}
}
