::-webkit-scrollbar {
	width: 8px;
	height: 8px;
	background: #ccc;
	// display: none;  /* Ocultar scroll */
}

::-webkit-scrollbar-thumb {
	background-color: #a27dea;
	border-radius: 4px;
}

#streaming {
	overflow: hidden;
	background-image: url(../../assets/images/streaming/bg.png);
	background-repeat: no-repeat;
	background-position: top center;
	background-size: cover;
	font-size: 18px;
	color: var(--bg-color-inverted);
	padding: 0 0 60px 0;

	& .header {
		width: 100%;
	}

	& .contenedor-streaming {
		padding: 56.25% 0 0 0;
		position: relative;

		& .estilo-streaming {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border: none;
		}
	}

	& .contentenedor-chat {
		height: 100%;
	}

	& button {
		background-color: #00f5d8;
		font-family: 'Poppins-Bold.ttf';
		color: #707070;
		padding: 15px;
		width: 220px;
		font-size: 18px;
		margin: auto;
		margin-top: 1%;
	}
}

@media only screen and (max-width: 600px) {
	#streaming {
		& .header {
			& img {
				width: 25%;
			}
		}
		& .contentenedor-chat {
			height: 400px;
		}
	}
}
@media only screen and (min-width: 600px) {
	#streaming {
		& .header {
			& img {
				width: 15%;
			}
		}
		& .contentenedor-chat {
			height: auto;
		}
	}
}
@media only screen and (min-width: 768px) {
	#streaming {
		& .header {
			& img {
				width: 25%;
			}
		}
	}
}
@media only screen and (min-width: 992px) {
	#streaming {
		& .header {
			& img {
				width: 10%;
			}
		}
		& .contentenedor-chat {
			height: 100%;
		}
	}
}
@media only screen and (min-width: 1200px) {
	#streaming {
	}
}
