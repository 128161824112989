::-webkit-scrollbar {
	width: 8px;
	height: 8px;
	background: #ccc;
	// display: none;  /* Ocultar scroll */
}

::-webkit-scrollbar-thumb {
	background-color: #a27dea;
	border-radius: 4px;
}

#encuesta {
	// overflow: hidden;
	height: 100vh;
	background-repeat: no-repeat;
	background-position: top center;
	background-size: cover;
	font-size: 18px;
	color: var(--bg-color-inverted);

	&::after {
		content: '';
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		position: fixed;
		z-index: -1;
		background-repeat: no-repeat;
		background-position-y: 100%;
		background-position-x: center;
		background-size: cover;
		background-position: center;
		background-image: url(../../assets/images/streaming/bg.png);
		// background-image: url(../../assets/images/bg.png);
	}

	& .separador {
		width: 100%;
		height: 40px;
	}

	& .header {
		width: 100%;
	}

	& .pregunta {
		font-size: 20px;
	}

	& .MuiTypography-root {
		font-size: 18px;
	}

	& .btn-enviar {
		background: #ad80f7;
		font-size: 18px;
		border-radius: 10px;
		padding: 10px;
		color: #fff;
	}
}

@media only screen and (max-width: 600px) {
}
@media only screen and (min-width: 600px) {
}
@media only screen and (min-width: 768px) {
}
@media only screen and (min-width: 992px) {
}
@media only screen and (min-width: 1200px) {
}
